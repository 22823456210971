// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	// filter: brightness(0.5);
	.owl-subtitle,
	.owl-btn,
	.owl-description,
	.owl-dots,
	.owl-nav {
		display: none;
	}

	.owl-title {
		font-family: $font-family-secondary;
		font-weight: 900;
		font-size: 68px;
		text-align: center;
		margin: auto;
		max-width: 70%;
		@include media-breakpoint-down(md) {
			font-size: 40px;
			word-wrap: break-word;
			max-width: 95%;
		}
	}
}

section.eyecatcher {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		height: 12vh;
		width: 100%;
		background: url("/images/white-wave.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: bottom;
		z-index: 100;
	}
	@include media-breakpoint-down(md) {
		&::after {
			height: 20vh;
			background: url("/images/eyecatcher-wave-mobile.png");
			background-repeat: no-repeat;
			background-position: bottom;
		}
	}
}
.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 50vh;
	min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 80vh;
	}
}

.content-eyecatcher-section {
	background: $white;
	.owl-carousel {
		.owl-item {
			height: 90vh;
			// filter: brightness(0.5);
			@include media-breakpoint-down(md) {
				height: 60vh;
			}
			.owl-subtitle {
				display: none;
			}

			.owl-title {
				font-family: $font-family-secondary;
				font-weight: 900;
				font-size: 68px;
				text-align: center;
				margin: auto;
				max-width: 70%;
				@include media-breakpoint-down(md) {
					font-size: 34px;
				}
			}
			.owl-description {
				font-size: $h3-font-size;
				font-family: $font-family-teritary;
				@include media-breakpoint-down(md) {
					font-size: 16px;
				}
			}
		}
		.owl-dots {
			display: none;
		}
		.owl-nav {
			position: absolute;
			top: 4.5rem;
			right: 2.5rem;
			@include media-breakpoint-down(md) {
				top: -2.5rem;
				z-index: 999!important;
			}

			.owl-next,
			.owl-prev {
				transform: none;
				position: relative;
				width: 32px;
				font-size: 24px;

				i {
					&::before {
						content: "\f138";
						font-size: 24px;
					}
				}
			}

			.owl-prev i {
				transform: scaleX(-1);
				margin-right: 0.5rem;
			}
		}
	}
}

// mini-sab
.mini-sab {
z-index: 1000;
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 15px 0 0 0;
			padding: 15px 0;
			border-radius: 8px;
			background: $green;
			box-shadow: 0px 0px 12px #00000029;
			color: #fff;

			// below eyecatcher
			// @include media-breakpoint-up(lg) {
			// 	margin-top: -50px;
			// 	padding-left: 30px;
			// 	padding-right: 30px;
			// }

			// above eyecatcher
			 @include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 6vh;
			}
@include media-breakpoint-down(md){
margin-top: -6vh;
}
		}
	}
}

// header
.header {
	z-index: 998;
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow;
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				min-height: 12vh;

				@include media-breakpoint-down(md) {
					flex-flow: row wrap;
					align-items: center;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// book button
	div.book-button {
		position: fixed;
		top: 0;
		right: 0;
		transition: 0.5s ease-in;
		a {
			background: $green-dark;
			border-radius: 0 0 0 100%;
			display: flex;
			justify-content: center;
			min-width: 175px;
			min-height: 200px;
			text-decoration: none;
			.list-item-suffix {
				display: none;
			}
			.caption {
				display: flex;
				flex-flow: column;
				justify-content: center;
				text-align: center;
				max-width: 100%;
				width: 70%;
				border-radius: 0 0 0 100%;
				padding: 1.5rem;
				transform: translate(12%, -12%);
				.list-item-title {
					color: $white;
					text-transform: uppercase;
					font-size: $h4-font-size;
					font-family: $font-family-secondary;
				}
				.list-item-short-description {
					color: $white;
					font-family: $font-family-teritary;
					font-size: 12px;
				}
			}
			&:hover {
				background: $green;
				transform: scale(1.05);
				box-shadow: $shadow;
			}
		}
		&.hide {
			transform: translate(100%, -100%);
			transition: 0.5s ease-out;
		}
		@include media-breakpoint-down(md) {
			a {
				min-width: 1rem;
				min-height: 1rem;
				max-width: 40vw !important;

				.caption {
					padding: 1.25rem;
					.list-item-title {
						font-size: 18px;
					}
					.list-item-short-description {
						font-size: 10px;
					}
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		i {
			color: $green-dark;
		}
	}

	// logo
	.logo {
		width: 229px;
		position: absolute;
		left: 1.75rem;
		top: 1.85rem;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(md) {
			width: 200px;
			position: relative;
		}
		&::before {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-55%, -60%);
			z-index: -1;
			width: 120%;
			aspect-ratio: 1/1;
			border-radius: 50%;
			background: radial-gradient(#fff, #f2f2f2, rgba(#fff, 0));
			opacity: 0.55;
			filter: blur(15px);
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			padding: 2.5rem 1rem;
			.nav-item {
				.nav-link {
					font-family: $font-family-secondary;
					font-weight: 600;
				}

				&.active,
				&:hover {
					.nav-link {
						color: $green-dark;
					}
				}
			}

			.dropdown-menu.show {
				border: 0;
				box-shadow: none;
				position: static !important;
				transform: none !important;
				padding: 0 !important;
				&::after,
				&::before {
					display: none;
				}

				.nav-item {
					.nav-link {
						font-weight: 200;
						margin-left: 0.5rem;
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			.navbar-nav {
				display: flex;
				gap: 1.5rem;
				.nav-item {
					.nav-link {
						font-family: $font-family-secondary;
					}
					&.active,
					&:hover {
						.nav-link {
							color: $green-dark;
						}
					}
				}
				.dropdown-menu.show {
					border: 0;
					box-shadow: none;
					&::after,
					&::before {
						display: none;
					}
					.nav-item {
						.nav-link {
							text-align: center;
						}
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
	}
}

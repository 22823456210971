// footer
.footer {
	background: $white;
	div.floating-button {
		position: fixed;
		right: 1.5rem;
		bottom: 1.5rem;
		z-index: 1000;
		a {
			display: flex;
			flex-flow: column;
			border: 1px solid $green-dark;
			background: $white;
			box-shadow: 0px 0px 4px #00000029;
			text-decoration: none;
			border-radius: 4px;
			padding: 0.25rem 1rem;
			box-shadow: $shadow;
			img {
				order: 1;
				max-width: 142px;
			}
			.caption {
				.list-item-title {
					font-size: 12px;
					font-weight: 600;
					font-family: $font-family-primary;
					color: $green-dark;
					text-decoration: none;
					letter-spacing: 1.2px;
				}
			}
			.list-item-suffix,
			.list-item-short-description {
				display: none;
			}
			&:hover {
				border: 1px solid $green-light;
			}
		}
		@include media-breakpoint-down(md) {
			transform: scale(0.8);
			right: 0.5rem;
		}
	}
	.footer-intro {
		position: relative;
		padding: 3vh 0 6vh 0;
		margin: -1rem auto 0 auto;
		.container {
			.container-holder {
				margin: auto;
				.column {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-flow: column;
					gap: 1.5rem;
					text-align: center;
					div.footer-contact {
						ul.footer-contact {
							display: flex;
							justify-content: center;
							gap: 1rem;
							a {
								padding: 0.5rem 1rem;
								border-radius: 6px;
								background: $green;
								display: flex;
								flex-flow: row;
								align-items: center;
								gap: 1.5rem;
								text-decoration: none;
								color: $green-dark;
								min-width: 232px;
								i {
									font-size: 20px;
									font-weight: 600;
									transition: 0.35s ease;
								}
								.list-item-suffix {
									display: none;
								}
								.caption {
									display: flex;
									flex-flow: column;
									align-items: flex-start;
									.list-item-title {
										font-family: $font-family-secondary;
										font-weight: 600;
										font-size: 18px;
										color: $white;
									}
									.list-item-short-description {
										font-size: 12px;
										font-family: $font-family-primary;
										font-weight: 600;
									}
								}
								&:hover {
									i {
										transform: rotate(360deg);
										color: $white;
										transition: 0.35s ease;
									}
									.list-item-short-description {
										color: $white;
										transition: 0.35s ease;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.footer-links {
		position: relative;
		padding-top: 12vh;
		&::before {
			content: "";
			position: absolute;
			top: -1px;
			left: 0;
			width: 100%;
			height: 6vh;
			background: url("/images/footer-wave.svg");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: bottom;
			@include media-breakpoint-down(md) {
				background: url("/images/footer-mobile-wave.svg");
				background-repeat: no-repeat;
				background-size: 100%;
				height: 6vh;
				background-position: top;
			}
		}
		.one,
		.two,
		.three {
			max-width: 18%;
			flex-basis: 18%;
			@include media-breakpoint-down(md) {
				max-width: 50%;
				flex-basis: 50%;
			}
		}
		.three,
		.four {
			@include media-breakpoint-down(md) {
				max-width: 100%;
				flex-basis: 100%;
			}
		}
		.four {
			max-width: 44%;
			flex-basis: 44%;
			position: relative;
			@include media-breakpoint-down(md) {
				max-width: 100%;
				flex-basis: 100%;
			}
			.footer-text {
				h5 {
					font-family: $font-family-teritary;
					font-weight: 400;
					margin-bottom: 1rem;
				}
				p {
					color: $green-light !important;
					font-size: 14px !important;
					font-weight: 300;
					font-family: $font-family-primary !important;
					float: left;
				}
			}
			div.vocabulary-term-list {
				position: absolute;
				right: 15px;
				bottom: 15px;
				float: right;
				ul {
					display: flex;
					gap: 1rem;
					li {
						.list-item-title {
							display: none;
						}
						i {
							font-size: 24px;
						}
						&:hover {
							i {
								transform: scale(1.05);
							}
						}
					}
				}
			}
		}
		.footer-list {
			h5 {
				font-family: $font-family-teritary;
				font-weight: 400;
				margin-bottom: 1rem;
			}
		}
		ul.list {
			li {
				a.link {
					text-decoration: none !important;
					color: $green-light !important;
					font-size: 14px !important;
					font-weight: 300;
					font-family: $font-family-primary !important;

					&:hover {
						text-decoration: underline !important;
					}
				}
			}
		}
	}
	.footer-socket {
		background: $green-dark;
		padding: 0.75rem;
		@include media-breakpoint-down(md) {
			padding: 0.5rem 0;
		}
		.container {
			.list {
				display: flex;
				gap: 1.5rem;
				width: 100%;
				justify-content: center;
				@include media-breakpoint-down(md) {
					flex-flow: column;
					gap: 0.35rem;
				}
				a {
					text-decoration: none !important;
					color: $green-light !important;
					font-size: 14px !important;
					font-weight: 300;
					font-family: $font-family-primary !important;

					&:hover {
						text-decoration: underline !important;
					}
				}
			}
		}
	}
	.footer-partner-section {
		min-height: 9vh;
		padding: 1rem;
		@include media-breakpoint-down(md) {
			padding: 1rem 15px;
		}
		ul.partner-logos {
			width: 100%;
			height: 9vh;
			display: flex;
			gap: 1.5rem;
			align-items: center;
			justify-content: center;
			@include media-breakpoint-down(md) {
				gap: 15px;
			}
			li {
				opacity: 0.85;
				.caption {
					display: none;
				}
				&:hover {
					opacity: 1;
				}
				@include media-breakpoint-down(md) {
					max-width: 62px;
				}
			}
		}
	}
}

// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// subtitle
.subtitle {
	font-family: $font-family-teritary;
	font-weight: 400;
}
.description,
.description p {
	font-weight: 300;
	font-family: $font-family-primary;
}

// default-card
.default-card {
	border-radius: 8px;
	box-shadow: $shadow;
	border: 0;
	background: $off-white;
	.card-image {
		position: relative;
		img {
			// filter: brightness(0.5);
		}

		&::after {
			content: "";
			position: absolute;
			bottom: -1px;
			left: 0;
			width: 101%;
			height: 10%;
			background: url("/images/white-wave.svg");
			background-position: bottom;
			background-repeat: no-repeat;
			background-size: 100%;
			z-index: 999;
			@include media-breakpoint-down(md) {
				background-size: contain;
				height: 25%;
			}
		}
	}
	.card-title {
		@extend .subtitle;
	}
	.card-subtitle {
		font-size: $h6-font-size;
		font-family: $font-family-primary;
		font-weight: 300;
	}
}

* {
	&::selection {
		background: $green-light;
		color: $green-dark;
	}
}

// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}
// accommodation section
.accommodation-section {
	margin: 3vh auto;
	.intro {
		.container-holder {
			max-width: 70%;
			margin: auto;
			text-align: center;
		}
	}
	@include media-breakpoint-down(md) {
		margin: 6vh 0;
		.intro .container-holder {
			max-width: 90%;
		}
	}
	.highlighted-block {
		background: $white;
		box-shadow: $shadow;
		margin: 6vh auto;
		border-radius: 8px;
		box-shadow: 0px 0px 12px $gray;

		.one {
			margin: 0;
			padding: 0 !important;
			max-width: 60%;
			flex-basis: 60%;
			&::after {
				content: "";
				position: absolute;
				top: 0;
				right: 2.65rem;
				height: 100%;
				width: 6.5rem;
				z-index: 999;
				background: url("/images/vertical-wave.svg");
				background-size: contain;
				background-repeat: no-repeat;
				background-position: left;
				transform: scaleX(-1);
				pointer-events: none;
			}
		}
		.two {
			max-width: 40%;
			flex-basis: 40%;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			.title,
			.description {
				max-width: 70%;
			}
			.button-link {
				width: 100%;
				max-width: 70%;

				a {
					@extend .btn, .btn-primary;
					margin-right: auto;
					max-width: max-content;
				}
			}
		}
		.carousel {
			position: relative !important;
			border-radius: 8px;
			.owl-caption,
			.owl-dots {
				display: none;
			}
			.owl-item,
			.item {
				min-height: 50vh;
				border-radius: 8px;
				// filter: brightness(0.5);
			}
			.owl-nav {
				position: absolute;
				top: 1rem;
				right: 2.5rem;
				.owl-next,
				.owl-prev {
					transform: none;
					position: relative;
					width: 32px;
					font-size: 24px;
					i {
						&::before {
							content: "\f138";
							font-size: 24px;
						}
					}
				}
				.owl-prev i {
					transform: scaleX(-1);
					margin-right: 0.5rem;
				}
			}
		}
		&.reversed {
			.container-holder {
				flex-flow: row-reverse;
				.one {
					&::after {
						right: unset;
						left: -2px;
						transform: none;
						background-position: left;
					}
					padding: 0 !important;
				}
				.two {
					align-items: center;
				}
			}
		}
		@include media-breakpoint-down(md) {
			margin: 9vh auto;
			max-width: 90%;
			.one,
			.two {
				max-width: calc(100%);
				flex-basis: 100%;
				padding: 1rem;
				.title,
				.description {
					max-width: 100%;
				}
			}
			.two {
				padding-bottom: 6vh !important;
			}
			.owl-nav {
				right: 0.5rem !important;
				top: 0.5rem !important;
			}
			.one::after {
				content: "";
				background: url("/images/mobile-wave-white.svg");
				background-position: bottom;
				background-repeat: no-repeat;
				background-size: contain;
				z-index: 999;
				left: 0;
				bottom: -1px;
				top: unset;
				height: 20%;
				width: 10%;
			}
			&.reversed {
				.container-holder {
					flex-flow: column;
					.two {
						align-items: flex-start;
					}
					.one::after {
						background-position: bottom;
					}
				}
			}
		}
	}
}

// facilities section
.facilities-section {
	margin: 12vh 0 !important;
	padding: 18vh 0 !important;
	position: relative;
	@include media-breakpoint-down(md) {
		padding: 12vh 0 !important;
	}
	&::after {
		content: "";
		position: absolute;
		bottom: -1px;
		left: 0;
		width: 100%;
		height: 6vh;
		background: url("/images/wave-green-dark.svg");
		background-size: cover;
		background-position: bottom;
		background-repeat: no-repeat;
		transform: scaleY(-1) scaleX(-1);
		@include media-breakpoint-down(md) {
			background: url("/images/mobile-wave-gray.svg");
			background-repeat: no-repeat;
			background-size: 100%;
			height: 9vh;
			background-position: bottom;
			transform: scaleX(-1);
		}
	}
	&::before {
		content: "";
		position: absolute;
		top: -1px;
		left: 0;
		width: 100%;
		height: 6vh;
		background: url("/images/wave-green-dark.svg");
		background-size: cover;
		background-position: bottom;
		background-repeat: no-repeat;
		// transform: scaleY(-1) scaleX(-1);
		@include media-breakpoint-down(md) {
			background: url("/images/mobile-wave-gray.svg");
			background-repeat: no-repeat;
			background-size: 100%;
			height: 9vh;
			background-position: bottom;
			transform: scaleY(-1);
		}
	}
	.grid {
		.grid-items {
			.item {
				max-width: 80%;
				flex-basis: 80%;
				.card {
					border-radius: 4px;
					background: $green-light;
					border: 0;
					box-shadow: none;
					filter: brightness(0.98);
					.card-image {
						display: none;
					}
					.card-body {
						min-width: 100%;
						display: flex !important;
						flex-flow: row !important;
						padding: 1.5rem;
						.card-caption {
							max-width: 60%;
							flex-basis: 60%;
							.card-title {
								line-height: 0.8;
								margin-bottom: 0.35rem;
								@extend .subtitle;
								font-size: $h4-font-size;
								color: $green-dark;
							}
							.card-description {
								@extend .description;
								line-height: auto;
								font-size: $font-size-base;
							}
						}
						.card-buttons {
							max-width: 40%;
							flex-basis: 40%;
							float: right;
							margin: 0;
							display: flex;
							align-items: center;
							justify-content: flex-end;
							padding-right: 1.5rem;
							.card-btn {
								@extend .btn-secondary;
							}
						}
					}
					&:hover {
						box-shadow: $shadow;
						filter: brightness(1);
						transform: scale(1.05);
						transition: 0.35s ease;
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			.grid-items {
				.item {
					max-width: 100%;
					flex-basis: 100%;
					.card {
						.card-body {
							flex-flow: column !important;
						}
						.card-caption,
						.card-title,
						.card-description,
						.card-buttons {
							max-width: 100% !important;
							flex-basis: 100% !important;
						}
						.card-buttons {
							display: block !important;
							margin: 1rem 0 0 0 !important;
						}
					}
				}
			}
		}
	}
}

// surroundings section
.surroundings-section {
	.grid {
		.grid-items {
			.item {
				flex-basis: 100%;
				max-width: 100%;
				.card {
					background: none;
					border: 0;
					box-shadow: none;
					padding-bottom: 2.5rem;
					.card-image,
					.card-body {
						max-width: 50%;
						flex-basis: 50%;
						@include media-breakpoint-down(md) {
							max-width: 100%;
							flex-basis: unset;
						}
					}
					.card-image,
					.card-image img {
						border-radius: 6px;
						overflow: hidden;
					}
					.card-image {
						box-shadow: $shadow;
					}
					.card-body {
						padding-left: 2rem;
						display: flex;
						justify-content: center;
						.card-caption {
							flex: unset !important;
						}
						.card-title {
							color: $green-dark;
							@extend .title;
							font-size: $h2-font-size;
						}
					}
				}
				&:nth-child(even) {
					.card {
						flex-flow: row-reverse !important;
					}
				}
				@include media-breakpoint-down(md) {
					.card {
						flex-flow: column !important;
						.card-body {
							padding-left: 0;
						}
					}
					&:nth-child(even) {
						.card {
							flex-flow: column !important;
							.card-body {
								flex-basis: unset !important;
							}
						}
					}
				}
			}
		}
	}
}

.content-eyecatcher-section {
	margin: 6vh 0 0 0;
	padding: 0;
	position: relative;
	&::after {
		content: "";
		position: absolute;
		bottom: -2px;
		left: 0;
		width: 100%;
		height: 6vh;
		background: url("/images/eyecatcher-content-bottom.png");
		background-size: cover;
		background-position: bottom;
		background-repeat: no-repeat;
		z-index: 990;
		@include media-breakpoint-down(md) {
			background: url("/images/mobile-wave-white.svg");
			background-repeat: no-repeat;
			background-size: 100%;
			height: 8vh;
			background-position: bottom;
			transform: scaleX(-1);
		}
	}

	&::before {
		content: "";
		position: absolute;
		top: -2px;
		left: 0;
		width: 100%;
		height: 6vh;
		background: url("/images/eyecatcher-content-top.svg");

		background-size: cover;
		background-position: bottom;
		background-repeat: no-repeat;
		z-index: 990;
		@include media-breakpoint-down(md) {
			background: url("/images/mobile-wave-gray.svg");
			background-repeat: no-repeat;
			background-size: 100%;
			height: 8vh;
			background-position: bottom;
			transform: scaleY(-1);
		}
	}
}

// news section
.news-section {
	background: $white;

	padding: 0.25rem 0 0.25rem 0 !important;

	.intro {
		.container-holder {
			max-width: 80%;
			margin: 6vh auto;
			text-align: center;
		}
	}
	.slider-container {
		max-width: 70vw;
		margin: 1rem auto;
		.owl-stage-outer {
			padding-bottom: 1rem;
		}
		.owl-nav,
		.owl-dots {
			display: none;
		}
		.item {
			.card {
				@extend .default-card;
			}
		}
	}
	@include media-breakpoint-down(md) {
		padding: unset !important;
		padding-top: 3vh !important;
		.intro {
			.container-holder {
				max-width: 100%;
				margin: 1vh auto;
				text-align: center;
			}
		}
		.slider-container {
			max-width: 100%;
			margin: 1rem auto;
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin: 0 15px 0 0;
				padding: 0.35rem 1rem;
				border-radius: 6px;
				background-color: $green-light;
				font-weight: 700;
			}

			.icon {
				margin-right: 5px;
				i::before {
					font-weight: 200;
				}
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
